.TerminalViewTerminalRow {
    display: flex;
    flex-direction: column;
}

.TerminalViewTerminals {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 16px;
}
