.TerminalViewSingleTerminal {
    padding: 8px;

    button {
        margin: .25rem;
        padding: .25rem;
        height: 6rem;
        width: 12rem;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
        background-image: linear-gradient(225deg, rgba(222, 222, 222, .5), rgba(233, 233, 233, 0.5));
        border: solid;
        border-color: rgba($color: #000000, $alpha: .3);
        border-radius: .25rem;
        border-width: .1rem;
        cursor: pointer;

        &:hover {
            background-image: linear-gradient(45deg, rgba(222, 222, 222, .5), rgba(233, 233, 233, 0.5));
        }

        img {
            max-height: 5rem;
            max-width: 11rem;
            justify-content: space-around;
        }
    }

    &-disabled {
        filter: brightness(75%) grayscale(100%);
        pointer-events: none;
    }
}

.TerminalViewTerminals {
    width: 100%;
    display: flex;
}

.terminal-idp-hints {
    display: flex;
    gap: 0.2rem;
    padding: 0.1rem 0.5rem 0 0.5rem;
    position: absolute;
    background: #efddb5;
    margin-top: -1.6rem;
    border-radius: $border-radius;
    border: solid $color-background-gray;
    border-width: 1px;
}